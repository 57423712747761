<template>
  <v-container fluid grid-list-lg>
    <Create
      :VendorId="VendorId"
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getList()"
    ></Create>
    <v-card :loading="loading" outlined>
      <ListTitle
        :color="$style.purchase.titlebar"
        :access="checkRightStatus(31) && VendorId"
        title="Payment"
        @add="add"
      />

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex v-if="!showAll" sm4 xs12>
          <DateTimePicker
            defaultRange="since60DaysAgo"
            :range="true"
            v-model="dateTime"
            @submit="getList()"
          />
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="payments"
          :search="search"
          :loading="loading"
          @current-items="updateTotal"
          :sort-by="sortBy"
          sort-desc
          :page.sync="page"
          :expanded.sync="expanded"
          show-expand
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item, isExpanded, expand }">
            <tr class="pointer">
              <td class="text-left">
                <v-btn @click="expand(true)" icon v-if="!isExpanded"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-btn @click="expand(false)" icon v-if="isExpanded"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                >
              </td>
              <td class="text-left">
                {{ item.date | moment("MM/DD/YYYY hh:mm A") }}
              </td>
              <td class="text-left">
                {{ item.localId }}
              </td>
              <td class="text-left" v-if="!VendorId">
                {{ item.Vendor.name }}
              </td>
              <td class="text-left">
                {{ item.note }}
              </td>
              <td class="text-right">
                {{ item.total | currency }}
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item v-if="!VendorId" @click="viewProfile(item)">
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!VendorId"></v-divider>
                    <v-list-item @click="viewpayment(item)">
                      <v-list-item-title>View payment</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-card color="#F3F8FC" text class="elevation-0">
                <v-card-text class="title">
                  <PaymentDetail :PaymentId="item.id"></PaymentDetail>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >

          <template v-slot:body.append="{ headers }">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td
                :colspan="headers.length - 2"
                class="text-right font-weight-bold"
              >
                List Total
              </td>
              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td
                :colspan="headers.length - 2"
                class="text-right font-weight-bold"
              >
                Result Total
              </td>
              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import paymentService from "../service";
const DateTimePicker = () => import("@/components/DateTimePicker");
import PaymentDetail from "./PaymentDetailList";
import { mapGetters } from "vuex";

const Create = () => import("./Create");

export default {
  props: {
    VendorId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "payment-list",
  data() {
    return {
      fullTotal: 0,
      total: 0,
      expanded: [],
      singleExpand: true,
      dateTime: {
        start: this.$moment()
            .startOf("days")
            .subtract(60, "days")
            .toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      selectedPayee: null,
      headers: [],
      payments: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "localId",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    // this.getList();
    this.loadHeader();
    this.$events.listen("purchasePaymentListEvent", () => this.getList());
  },
  beforeDestroy() {
    this.$events.remove("purchasePaymentListEvent");
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  watch: {
    VendorId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  components: {
    DateTimePicker,
    Create,
    PaymentDetail,
  },
  methods: {
    updateTotal(val) {
      this.total = 0;
      val.map((row) => {
        this.total += row.total;
      });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    viewProfile(val) {
      // // console.log("val", val);
      this.$router.push({ path: `/inventory/vendor/${val.VendorId}/profile` });
    },
    viewpayment(payment) {
      const routeData = this.$router.resolve({
        path: `/global/purchase/payment/${payment.id}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPackingSlip(payment) {
      const routeData = this.$router.resolve({
        path: `/global/payment/${payment.id}/packingSlip`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadHeader() {
      this.headers = [];
      this.headers.push({
        text: this.$t("labels.date"),
        align: "left",
        value: "date",
        sortable: true,
      });

      this.headers.push({
        text: "Payment Number",
        align: "left",
        value: "localId",
        sortable: true,
      });

      if (!this.VendorId) {
        this.headers.push({
          text: "Vendor Name",
          value: "Vendor.name",
          align: "left",
          sortable: true,
        });
      }

      this.headers.push({
        text: "Note",
        align: "left",
        value: "note",
        sortable: true,
      });

      this.headers.push({
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      });

      // this.headers.push({
      //   text: "Payment",
      //   value: "payment",
      //   align: "right",
      //   sortable: true
      // });

      this.headers.push({
        text: "",
        value: "action",
        sortable: false,
      });
    },
    getList() {
      this.loading = true;
      let where = {};
      if (!this.showAll) {
        where = {
          start: this.dateTime.start,
          end: this.dateTime.end,
        };
      }
      if (this.VendorId) {
        where.VendorId = this.VendorId;
      }

      return paymentService.getAll(where).then((response) => {
        // console.log("purchase payment", response);
        this.loading = false;
        this.payments = response.data;
        this.fullTotal = 0;
        this.payments.map((row) => {
          this.fullTotal += row.total;
        });
        return response;
      });
    },
  },
};
</script>
