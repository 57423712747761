<template>
  <v-card dark class="elevation-10">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="paymentDetail"
        :loading="loading"
        :sort-by="sortBy"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr class="pointer">
            <td @click="viewPurchase(item)" class="text-left">
              {{ item.Purchase.localId }}
            </td>

            <td @click="viewPurchase(item)" class="text-left">
              {{ item.amount | currency }}
            </td>

            <td class="justify-end align-center text-right">
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item @click="viewPurchase(item)">
                    <v-list-item-title>View payment</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="checkRightStatus(32)"
                    @click="deletePayment(item)"
                  >
                    <v-list-item-title>Delete Payment</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import paymentService from "../service";
// import DateTimePicker from "../../../../components/DateTimePicker";
import { mapGetters } from "vuex";
// import Hashids from "hashids";
// const hashids = new Hashids();
export default {
  props: {
    PaymentId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "payment-detail-list",
  data() {
    return {
      count: 0,
      loading: true,
      headers: [
        {
          text: "Purchase Number",
          align: "left",
          value: "localId",
          sortable: true,
        },
        {
          text: "Amount",
          align: "left",
          value: "amount",
          sortable: true,
        },

        {
          text: "",
          value: "action",
          sortable: false,
          width: "20px",
        },
      ],
      paymentDetail: [],
      search: null,
      page: 1,
      sortBy: "Purchase.localId",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  created() {
    this.getList();
  },
  methods: {
    viewPurchase(purchase) {
      // const hashId = hashids.encode(purchase.PurchaseId);

      const routeData = this.$router.resolve({
        path: `/global/purchase/${purchase.PurchaseId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    getList() {
      return paymentService.getDetailById(this.PaymentId).then((response) => {
        // console.log('paymentDetail ', response)
        this.paymentDetail = response.data;
        this.loading = false;
        return response;
      });
    },
    deletePayment(payment) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          paymentService.deletePaymentDetail(payment.id).then((response) => {
            // console.log('delete response', response)
            if (response.data) {
              this.$swal("Deleted!", "Payee has been deleted.", "success");
              this.getList();
              this.$events.emit("purchasePaymentListEvent");
              this.$events.emit("VendorProfileEvent");
              this.$events.emit("purchaseListEvent");
            }
          });
        }
      });
    },
  },
};
</script>
